import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    CircularProgress,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from '@mui/material';
import api from '../../Api/api'; // Import the custom api instance

const AddPortfolio = ({ open, onClose, onAddTestimonial }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [url, setUrl] = useState('');
    const [image, setImage] = useState(null);
    const [video, setVideo] = useState(null);
    const [category, setCategory] = useState(''); // New state for category
    const [nameError, setNameError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');
    const [urlError, setUrlError] = useState('');
    const [categoryError, setCategoryError] = useState(''); // Error state for category
    const [categories, setCategories] = useState([]); // State to store fetched categories
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    // Fetch categories when the component mounts
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const accessToken = localStorage.getItem('access_token');

                if (!accessToken) {
                    // Handle the case where there's no access token stored (user not logged in)
                    return;
                }

                const response = await api.get(`categories`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });

                setCategories(response.data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchCategories();
    }, []);

    const handleSave = async () => {
        setIsLoading(true);
        try {
            // Perform validation
            if (!name) {
                setNameError('Name is required');
                setIsLoading(false);
                return;
            }
            if (!description) {
                setDescriptionError('Description is required');
                setIsLoading(false);
                return;
            }
            if (!url) {
                setUrlError('Url is required');
                setIsLoading(false);
                return;
            }

            if (!category) {
                setCategoryError('Category is required');
                setIsLoading(false);
                return;
            }

            const newTestimonial = new FormData();
            newTestimonial.append('name', name);
            newTestimonial.append('description', description);
            newTestimonial.append('url', url);
            newTestimonial.append('image', image); // Append the image file
            newTestimonial.append('video', video); // Append the image file
            newTestimonial.append('category', category); // Append the selected category

            const accessToken = localStorage.getItem('access_token');

            const response = await api.post(
                `portfolios`,
                newTestimonial,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'multipart/form-data', // Set proper content type
                    },
                }
            );

            if (response.status === 201) {
                setIsLoading(false);
                onAddTestimonial(newTestimonial); // Add the new testimonial to the local state
                onClose(); // Close the dialog
            } else {
                setIsLoading(false);
                setError(response.data.message);
                // Handle error scenario here if needed
            }
        } catch (error) {
            setIsLoading(false);
            setError(error.response.data.message);
            // Handle error scenario here if needed
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Create Review</DialogTitle>
            <DialogContent>
                <TextField
                    label="Name"
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value);
                        setNameError('');
                    }}
                    fullWidth
                    sx={{ mt: 2 }}
                    error={nameError !== ''}
                    helperText={nameError}
                />
                <TextField
                    label="Url"
                    value={url}
                    onChange={(e) => {
                        setUrl(e.target.value);
                        setUrlError('');
                    }}
                    fullWidth
                    sx={{ mt: 2 }}
                    error={urlError !== ''}
                    helperText={urlError}
                />

                <TextField
                    label="Description"
                    value={description}
                    onChange={(e) => {
                        setDescription(e.target.value);
                        setDescriptionError('');
                    }}
                    fullWidth
                    sx={{ mt: 2 }}
                    error={descriptionError !== ''}
                    multiline
                    helperText={descriptionError}
                />
                <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel>Category</InputLabel>
                    <Select
                        value={category}
                        onChange={(e) => {
                            setCategory(e.target.value);
                            setCategoryError('');
                        }}
                        error={categoryError !== ''}
                    >
                        <MenuItem value="">Select Category</MenuItem>
                        {categories.map((cat) => (
                            <MenuItem key={cat.id} value={cat.id}>
                                {cat.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <label htmlFor="image-input">
                    <Typography variant="subtitle1" gutterBottom>
                        Image
                    </Typography>
                    <input
                        id="image-input"
                        type="file"
                        accept="image/*"
                        onChange={(e) => setImage(e.target.files[0])} // Store the selected image as a File object
                        style={{ display: 'none' }}
                    />
                    <Button
                        variant="outlined"
                        component="span"
                        color="primary"
                    >
                        Choose Image
                    </Button>
                </label>
                <label htmlFor="video-input">
                    <Typography variant="subtitle1" gutterBottom>
                        Video
                    </Typography>
                    <input
                        id="video-input"
                        type="file"
                        accept="video/*"
                        onChange={(e) => setVideo(e.target.files[0])}
                        style={{ display: 'none' }}
                    />
                    <Button
                        variant="outlined"
                        component="span"
                        color="primary"
                    >
                        Choose Video
                    </Button>
                </label>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="contained" color="error">
                    Cancel
                </Button>
                <Button
                    onClick={handleSave}
                    variant="contained"
                    color="success"
                    disabled={isLoading}
                >
                    {isLoading ? <CircularProgress size={20} /> : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddPortfolio;
