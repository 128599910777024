import axios from 'axios';

// Create an instance of axios
const api = axios.create({
    baseURL: 'http://api.2goforms.com/api/', // Set your API base URL here
});

// Add a response interceptor to catch "unauthenticated" errors
api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            // If the API returns 401 (unauthenticated) status code,
            // remove the access_token from local storage
            localStorage.removeItem('access_token');

            // Redirect to the login page using JavaScript's built-in window.location.href
            window.location.href = '/';
        }
        return Promise.reject(error);
    }
);

export default api;