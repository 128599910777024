import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button, CircularProgress,
} from '@mui/material';
import api from '../../Api/api'; // Import the custom api instance

const AddUser = ({ open, onClose, onAddUser }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false); // Add a loading state

    const handleSave = async () => {
        setIsLoading(true);
        try {
            // Perform validation if needed
            // ...

            const newUser = { name, email ,password};
            const accessToken = localStorage.getItem('access_token');

            const response = await api.post(
                `users`,
                newUser,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            if (response.status === 201) {
                setIsLoading(false);
                onAddUser(newUser); // Add the new user to the local state
                onClose(); // Close the dialog
            } else {
                setIsLoading(false);
                console.error('Error creating user:', response.data);
                // Handle error scenario here if needed
            }
        } catch (error) {
            setIsLoading(false);
            console.error('Error creating user:', error);
            // Handle error scenario here if needed
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Create User</DialogTitle>
            <DialogContent>
                <TextField
                    label="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    fullWidth
                    sx={{ mt: 2 }}
                />
                <TextField
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                    sx={{ mt: 2 }}
                />
                <TextField
                    label="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                    sx={{ mt: 2 }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="contained"
                        color="error">
                    Cancel
                </Button>
                <Button onClick={handleSave}
                        variant="contained"
                        color="success"
                        disabled={isLoading}>
                    {isLoading ? <CircularProgress size={20} /> : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddUser;