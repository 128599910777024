import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import LoginPage from './components/LoginPage';
import UserList from './components/user/UserList';
import './css/navigation-styles.css';
import { Container, Drawer, List, ListItem, ListItemText, AppBar, Toolbar, IconButton, Typography, ListItemIcon } from "@mui/material";
import CategoryList from "./components/category/CategoryList";
import TestimonialList from "./components/testimonial/TestimonialList";
import VideoReviewList from "./components/video-review/VideoReviewList";
import PortfolioList from "./components/portfolio/PortfolioList";
import MenuIcon from '@mui/icons-material/Menu';
import PeopleIcon from '@mui/icons-material/People';
import CategoryIcon from '@mui/icons-material/Category';
import StarIcon from '@mui/icons-material/Star';
import RateReviewIcon from '@mui/icons-material/RateReview';
import WorkIcon from '@mui/icons-material/Work';

import './App.css'; // Import your CSS for styling the active class

const App = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('access_token'));
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [menuItems, setMenuItems] = useState([
        { to: '/users', text: 'User List', icon: <PeopleIcon />, isActive: false },
        { to: '/categories', text: 'Category List', icon: <CategoryIcon />, isActive: false },
        { to: '/testimonials', text: 'Testimonial List', icon: <StarIcon />, isActive: false },
        { to: '/reviews', text: 'Reviews List', icon: <RateReviewIcon />, isActive: false },
        { to: '/portfolios', text: 'Portfolios List', icon: <WorkIcon />, isActive: false }
    ]);

    const handleLogin = (token) => {
        localStorage.setItem('access_token', token);
        setIsLoggedIn(true);
    };

    const handleLogout = () => {
        localStorage.removeItem('access_token');
        setIsLoggedIn(false);
        window.location.href = '/';
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleMenuItemClick = (index) => {
        const updatedMenuItems = menuItems.map((item, i) => ({
            ...item,
            isActive: i === index
        }));
        setMenuItems(updatedMenuItems);
    };

    return (
        <Router>
            <Container maxWidth="md">
                {isLoggedIn && (
                    <AppBar position="static">
                        <Toolbar>
                            <IconButton onClick={toggleSidebar} edge="start" color="inherit" aria-label="menu">
                                <MenuIcon />
                            </IconButton>
                            <Typography variant="h6" sx={{ flexGrow: 1 }}>
                                Dashboard
                            </Typography>

                                <button onClick={handleLogout} className="logout-button">Logout</button>

                        </Toolbar>
                    </AppBar>
                )}
                <Drawer anchor="left" open={isSidebarOpen} onClose={toggleSidebar}>
                    <List>
                        {menuItems.map((menuItem, index) => (
                            <ListItem
                                button
                                component={Link}
                                to={menuItem.to}
                                key={menuItem.to}
                                onClick={() => handleMenuItemClick(index)}
                                className={menuItem.isActive ? 'active' : ''}
                            >
                                <ListItemIcon>{menuItem.icon}</ListItemIcon>
                                <ListItemText primary={menuItem.text} />
                            </ListItem>
                        ))}
                    </List>
                </Drawer>
                <main>
                    <Routes>
                        {!isLoggedIn ? (
                            <Route path="/" element={<LoginPage onLogin={handleLogin} />} />
                        ) : (
                            <>
                                <Route path="/users" element={<UserList />} />
                                <Route path="/categories" element={<CategoryList />} />
                                <Route path="/testimonials" element={<TestimonialList />} />
                                <Route path="/reviews" element={<VideoReviewList />} />
                                <Route path="/portfolios" element={<PortfolioList />} />
                                <Route path="/*" element={<Navigate to="/users" />} />
                            </>
                        )}
                    </Routes>
                </main>
            </Container>
        </Router>
    );
};

export default App;