import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Button,
    Snackbar,
    Alert,
    Container,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions

} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';


import EditReviewVideo from './EditReviewVideo';
import AddReviewVideo from './AddReviewVideo';
import api from '../../Api/api'; // Import the custom api instance
import '../../css/custom-styles.css';

const VideoReviewList = () => {
    const [testimonials, setTestimonials] = useState([]);
    const [editingTestimonial, setEditingTestimonial] = useState(null);
    const [isAddTestimonialOpen, setIsAddTestimonialOpen] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [deleteConfirmation, setDeleteConfirmation] = useState(null);

    const handleDeleteConfirmation = (testimonial) => {
        setDeleteConfirmation(testimonial);
    };

    const handleCancelDelete = () => {
        setDeleteConfirmation(null);
    };

    const handleConfirmDelete = async (testimonial) => {
        try {
            await handleDeleteTestimonial(testimonial.id);
            setDeleteConfirmation(null);
        } catch (error) {
            // Handle error if needed
        }
    };



    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const accessToken = localStorage.getItem('access_token');

            if (!accessToken) {
                // Handle the case where there's no access token stored (user not logged in)
                return;
            }

            const response = await api.get(`reviews`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            setTestimonials(response.data);
        } catch (error) {
            console.error(error);
        }
    };
    const handleAddTestimonial = (newTestimonial) => {
        setTestimonials([...testimonials, newTestimonial]);
        fetchData();
        setShowSuccessMessage(true);
    };

    const handleEditTestimonial = (testimonial) => {
        setEditingTestimonial(testimonial);
        fetchData();
    };

    const handleDeleteTestimonial = async (testimonialId) => {
        try {
            const accessToken = localStorage.getItem('access_token');

            if (!accessToken) {
                // Handle the case where there's no access token stored (user not logged in)
                return;
            }

            const response = await api.delete(
                `reviews/${testimonialId}`, // Notice the API path is simplified, as the base URL is already set in api.js
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            if (response.status === 200) {
                // Remove the deleted user from the local state
                const updatedTestimonials = testimonials.filter((testimonial) => testimonial.id !== testimonialId);
                setTestimonials(updatedTestimonials);
                setShowSuccessMessage(true); // Show success message after deletion
            } else {
                console.error('Error deleting user:', response.data);
                // Handle error scenario here if needed
            }
        } catch (error) {
            console.error('Error deleting user:', error);
            // Handle error scenario here if needed
        }
    };

    const handleSaveTestimonial = (updatedTestimonial) => {

        // Save the updated user details to the server using API call
        // For simplicity, we'll just update the user details locally in this example
        const updatedTestimonials = testimonials.map((testimonial) =>
            testimonial.id === updatedTestimonial.id ? updatedTestimonial : testimonial
        );
        setTestimonials(updatedTestimonials);
        setShowSuccessMessage(true);
    };
    const handleCloseSuccessMessage = () => {
        setShowSuccessMessage(false);
    };

    const handleCloseErrorMessage = () => {
        setShowErrorMessage(false);
    };

    return (
        <Container maxWidth="md">
            <h2>Reviews List</h2>
            <Button
                variant="contained"
                color="primary"
                onClick={() => setIsAddTestimonialOpen(true)}
                sx={{mb:2}}
            >
                Add Video Review
            </Button>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Designation</TableCell>
                            <TableCell>Comment</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {testimonials.map((testimonial) => (
                            <TableRow key={testimonial.id}>
                                <TableCell>{testimonial.name}</TableCell>
                                <TableCell>{testimonial.designation}</TableCell>
                                <TableCell>{testimonial.comment}</TableCell>
                                <TableCell>
                                    <IconButton

                                        aria-label="edit"
                                        onClick={() => handleEditTestimonial(testimonial)}
                                        sx={{
                                            color: '#fff', // Apply your custom text color
                                            marginRight: '5px',
                                            backgroundColor: '#007bff', // Apply your custom background color
                                            '&:hover': {
                                                backgroundColor: '#eee', // Apply your custom hover background color
                                                color: '#007bff',
                                            },
                                        }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton
                                        aria-label="delete"
                                        onClick={() => handleDeleteConfirmation(testimonial)}

                                        sx={{
                                            color: '#fff', // Apply your custom text color
                                            backgroundColor: '#dc3545', // Apply your custom background color
                                            '&:hover': {
                                                backgroundColor: '#eee', // Apply your custom hover background color
                                                color: '#dc3545',
                                            },
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {editingTestimonial && (
                <EditReviewVideo
                    testimonial={editingTestimonial}
                    open={true}
                    onClose={() => setEditingTestimonial(null)}
                    onSave={handleSaveTestimonial}
                />
            )}
            {isAddTestimonialOpen && (
            <AddReviewVideo
                open={isAddTestimonialOpen}
                onClose={() => setIsAddTestimonialOpen(false)}
                onAddTestimonial={handleAddTestimonial}
            />
            )}
            <Snackbar
                open={showSuccessMessage}
                autoHideDuration={3000} // Adjust the duration as needed
                onClose={handleCloseSuccessMessage}
                message="Success: Review operation completed!"
                // You can customize the appearance of the snackbar here if needed
            >
                <Alert severity="success" >
                    Success: Testimonial operation completed!
                </Alert>
            </Snackbar>
            <Snackbar
                open={showErrorMessage}
                autoHideDuration={3000} // Adjust the duration as needed
                onClose={handleCloseErrorMessage}
                message="Error: Sorry operation not completed!"
                // You can customize the appearance of the snackbar here if needed
            >
                <Alert severity="error" >
                    Error: Sorry operation not completed!
                </Alert>
            </Snackbar>

            {deleteConfirmation && (
                <Dialog open={true} onClose={handleCancelDelete}>
                    <DialogTitle>Confirm Deletion</DialogTitle>
                    <DialogContent>
                        Are you sure you want to delete the Review: {deleteConfirmation.name}?
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancelDelete} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => handleConfirmDelete(deleteConfirmation)} color="primary">
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Container>
    );
};

export default VideoReviewList;