import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Button,
    Snackbar,
    Alert,
    Container,

} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';


import EditUser from './EditUser';
import AddUser from './AddUser';
import api from '../../Api/api'; // Import the custom api instance
import '../../css/custom-styles.css';

const UserList = () => {
    const [users, setUsers] = useState([]);
    const [editingUser, setEditingUser] = useState(null);
    const [isAddUserOpen, setIsAddUserOpen] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);



    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const accessToken = localStorage.getItem('access_token');

            if (!accessToken) {
                // Handle the case where there's no access token stored (user not logged in)
                return;
            }

            const response = await api.get(`users`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            setUsers(response.data);
        } catch (error) {
            console.error(error);
        }
    };
    const handleAddUser = (newUser) => {
        setUsers([...users, newUser]);
        fetchUsers();
        setShowSuccessMessage(true);
    };

    const handleEditUser = (user) => {
        setEditingUser(user);
    };

    const handleDeleteUser = async (userId) => {
        try {
            const accessToken = localStorage.getItem('access_token');

            if (!accessToken) {
                // Handle the case where there's no access token stored (user not logged in)
                return;
            }

            const response = await api.delete(
                `users/${userId}`, // Notice the API path is simplified, as the base URL is already set in api.js
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            if (response.status === 200) {
                // Remove the deleted user from the local state
                const updatedUsers = users.filter((user) => user.id !== userId);
                setUsers(updatedUsers);
                setShowSuccessMessage(true); // Show success message after deletion
            } else {
                console.error('Error deleting user:', response.data);
                // Handle error scenario here if needed
            }
        } catch (error) {
            console.error('Error deleting user:', error);
            // Handle error scenario here if needed
        }
    };

    const handleSaveUser = (updatedUser) => {

        // Save the updated user details to the server using API call
        // For simplicity, we'll just update the user details locally in this example
        const updatedUsers = users.map((user) =>
            user.id === updatedUser.id ? updatedUser : user
        );
        setUsers(updatedUsers);
        setShowSuccessMessage(true);
    };
    const handleCloseSuccessMessage = () => {
        setShowSuccessMessage(false);
    };

    const handleCloseErrorMessage = () => {
        setShowErrorMessage(false);
    };

    return (
        <Container maxWidth="md">
            <h2>User List</h2>
            <Button
                variant="contained"
                color="primary"
                onClick={() => setIsAddUserOpen(true)}
                sx={{mb:2}}
            >
                Add User
            </Button>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user) => (
                            <TableRow key={user.id}>
                                <TableCell>{user.name}</TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>
                                    <IconButton

                                        aria-label="edit"
                                        onClick={() => handleEditUser(user)}
                                        sx={{
                                            color: '#fff', // Apply your custom text color
                                            marginRight: '5px',
                                            backgroundColor: '#007bff', // Apply your custom background color
                                            '&:hover': {
                                                backgroundColor: '#eee', // Apply your custom hover background color
                                                color: '#007bff',
                                            },
                                        }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton
                                        aria-label="delete"
                                        onClick={() => handleDeleteUser(user.id)}
                                        sx={{
                                            color: '#fff', // Apply your custom text color
                                            backgroundColor: '#dc3545', // Apply your custom background color
                                            '&:hover': {
                                                backgroundColor: '#eee', // Apply your custom hover background color
                                                color: '#dc3545',
                                            },
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {editingUser && (
                <EditUser
                    user={editingUser}
                    open={true}
                    onClose={() => setEditingUser(null)}
                    onSave={handleSaveUser}
                />
            )}
            {isAddUserOpen && (
            <AddUser
                open={isAddUserOpen}
                onClose={() => setIsAddUserOpen(false)}
                onAddUser={handleAddUser}
            />
            )}
            <Snackbar
                open={showSuccessMessage}
                autoHideDuration={3000} // Adjust the duration as needed
                onClose={handleCloseSuccessMessage}
                message="Success: User operation completed!"
                // You can customize the appearance of the snackbar here if needed
            >
                <Alert severity="success" >
                    Success: User operation completed!
                </Alert>
            </Snackbar>
            <Snackbar
                open={showErrorMessage}
                autoHideDuration={3000} // Adjust the duration as needed
                onClose={handleCloseErrorMessage}
                message="Error: Sorry operation not completed!"
                // You can customize the appearance of the snackbar here if needed
            >
                <Alert severity="error" >
                    Error: Sorry operation not completed!
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default UserList;