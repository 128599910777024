import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    CircularProgress,
} from '@mui/material';
import axios from 'axios';
import api from '../../Api/api'; // Import the custom api instance

const EditUser = ({ user, open, onClose, onSave  }) => {
    const [name, setName] = useState(user.name);
    const [email, setEmail] = useState(user.email);
    const [isLoading, setIsLoading] = useState(false); // Add a loading state

    const handleSave = async () => {
        setIsLoading(true);
        try {
            const updatedUser = { ...user, name, email };
            const accessToken = localStorage.getItem('access_token');

            const response = await api.put(
                `users/${user.id}`,
                updatedUser,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            if (response.status === 200) {
                setIsLoading(false);
                onSave(updatedUser); // Update the user in the local state
                onClose(); // Close the dialog
            } else {
                setIsLoading(false);
                console.error('Error updating user:', response.data);
                // Handle error scenario here if needed
            }
        } catch (error) {
            setIsLoading(false);
            console.error('Error updating user:', error);
            // Handle error scenario here if needed
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Edit User</DialogTitle>
            <DialogContent>
                <TextField
                    label="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    fullWidth
                    sx={{ mt: 2 }} // Add margin-top of 2 units
                />
                <TextField
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                    sx={{ mt: 2 }} // Add margin-top of 2 units
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="contained"
                        color="error">
                    Cancel
                </Button>
                <Button onClick={handleSave}
                        variant="contained"
                        color="success"
                        disabled={isLoading}>
                    {isLoading ? <CircularProgress size={20} /> : 'Save'}

                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditUser;