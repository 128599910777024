import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Button,
    Snackbar,
    Alert,
    Container,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions

} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';


import EditCategory from './EditCategory';
import AddCategory from './AddCategory';
import api from '../../Api/api'; // Import the custom api instance
import '../../css/custom-styles.css';

const CategoryList = () => {
    const [categories, setCategories] = useState([]);
    const [editingCategory, setEditingCategory] = useState(null);
    const [isAddCategoryOpen, setIsAddCategoryOpen] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [deleteConfirmation, setDeleteConfirmation] = useState(null);

    const handleDeleteConfirmation = (category) => {
        setDeleteConfirmation(category);
    };

    const handleCancelDelete = () => {
        setDeleteConfirmation(null);
    };

    const handleConfirmDelete = async (category) => {
        try {
            await handleDeleteCategory(category.id);
            setDeleteConfirmation(null);
        } catch (error) {
            // Handle error if needed
        }
    };



    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const accessToken = localStorage.getItem('access_token');

            if (!accessToken) {
                // Handle the case where there's no access token stored (user not logged in)
                return;
            }

            const response = await api.get(`categories`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            setCategories(response.data);
        } catch (error) {
            console.error(error);
        }
    };
    const handleAddCategory = (newCategory) => {
        setCategories([...categories, newCategory]);
        fetchData();
        setShowSuccessMessage(true);
    };

    const handleEditCategory = (category) => {
        setEditingCategory(category);
    };

    const handleDeleteCategory = async (categoryId) => {
        try {
            const accessToken = localStorage.getItem('access_token');

            if (!accessToken) {
                // Handle the case where there's no access token stored (user not logged in)
                return;
            }

            const response = await api.delete(
                `categories/${categoryId}`, // Notice the API path is simplified, as the base URL is already set in api.js
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            if (response.status === 200) {
                // Remove the deleted user from the local state
                const updatedCategories = categories.filter((category) => category.id !== categoryId);
                setCategories(updatedCategories);
                setShowSuccessMessage(true); // Show success message after deletion
            } else {
                console.error('Error deleting user:', response.data);
                // Handle error scenario here if needed
            }
        } catch (error) {
            console.error('Error deleting user:', error);
            // Handle error scenario here if needed
        }
    };

    const handleSaveCategory = (updatedCategory) => {

        // Save the updated user details to the server using API call
        // For simplicity, we'll just update the user details locally in this example
        const updatedCategories = categories.map((category) =>
            category.id === updatedCategory.id ? updatedCategory : category
        );
        setCategories(updatedCategories);
        setShowSuccessMessage(true);
    };
    const handleCloseSuccessMessage = () => {
        setShowSuccessMessage(false);
    };

    const handleCloseErrorMessage = () => {
        setShowErrorMessage(false);
    };

    return (
        <Container maxWidth="md">
            <h2>Category List</h2>
            <Button
                variant="contained"
                color="primary"
                onClick={() => setIsAddCategoryOpen(true)}
                sx={{mb:2}}
            >
                Add Category
            </Button>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Slug</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {categories.map((category) => (
                            <TableRow key={category.id}>
                                <TableCell>{category.name}</TableCell>
                                <TableCell>{category.slug}</TableCell>
                                <TableCell>
                                    <IconButton

                                        aria-label="edit"
                                        onClick={() => handleEditCategory(category)}
                                        sx={{
                                            color: '#fff', // Apply your custom text color
                                            marginRight: '5px',
                                            backgroundColor: '#007bff', // Apply your custom background color
                                            '&:hover': {
                                                backgroundColor: '#eee', // Apply your custom hover background color
                                                color: '#007bff',
                                            },
                                        }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton
                                        aria-label="delete"
                                        onClick={() => handleDeleteConfirmation(category)}

                                        sx={{
                                            color: '#fff', // Apply your custom text color
                                            backgroundColor: '#dc3545', // Apply your custom background color
                                            '&:hover': {
                                                backgroundColor: '#eee', // Apply your custom hover background color
                                                color: '#dc3545',
                                            },
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {editingCategory && (
                <EditCategory
                    category={editingCategory}
                    open={true}
                    onClose={() => setEditingCategory(null)}
                    onSave={handleSaveCategory}
                />
            )}
            {isAddCategoryOpen && (
            <AddCategory
                open={isAddCategoryOpen}
                onClose={() => setIsAddCategoryOpen(false)}
                onAddCategory={handleAddCategory}
            />
            )}
            <Snackbar
                open={showSuccessMessage}
                autoHideDuration={3000} // Adjust the duration as needed
                onClose={handleCloseSuccessMessage}
                message="Success: User operation completed!"
                // You can customize the appearance of the snackbar here if needed
            >
                <Alert severity="success" >
                    Success: Category operation completed!
                </Alert>
            </Snackbar>
            <Snackbar
                open={showErrorMessage}
                autoHideDuration={3000} // Adjust the duration as needed
                onClose={handleCloseErrorMessage}
                message="Error: Sorry operation not completed!"
                // You can customize the appearance of the snackbar here if needed
            >
                <Alert severity="error" >
                    Error: Sorry operation not completed!
                </Alert>
            </Snackbar>

            {deleteConfirmation && (
                <Dialog open={true} onClose={handleCancelDelete}>
                    <DialogTitle>Confirm Deletion</DialogTitle>
                    <DialogContent>
                        Are you sure you want to delete the category: {deleteConfirmation.name}?
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancelDelete} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => handleConfirmDelete(deleteConfirmation)} color="primary">
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Container>
    );
};

export default CategoryList;