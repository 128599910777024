import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    CircularProgress,
    Typography,
} from '@mui/material';
import api from '../../Api/api'; // Import the custom api instance

const AddReviewVideo = ({ open, onClose, onAddTestimonial }) => {
    const [name, setName] = useState('');
    const [designation, setDesignation] = useState('');
    const [rating, setRating] = useState('');
    const [comment, setComment] = useState('');
    const [image, setImage] = useState(null);
    const [video, setVideo] = useState(null);
    const [nameError, setNameError] = useState('');
    const [designationError, setDesignationError] = useState('');
    const [ratingError, setRatingError] = useState('');
    const [commentError, setCommentError] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

   
    const handleSave = async () => {
        setIsLoading(true);
        try {
            // Perform validation
            if (!name) {
                setNameError('Name is required');
                setIsLoading(false);
                return;
            }
            if (!designation) {
                setDesignationError('Designation is required');
                setIsLoading(false);
                return;
            }
            if (!rating) {
                setRatingError('Rating is required');
                setIsLoading(false);
                return;
            }
            if (!comment) {
                setCommentError('Comment is required');
                setIsLoading(false);
                return;
            }

            const newTestimonial = new FormData();
            newTestimonial.append('name', name);
            newTestimonial.append('designation', designation);
            newTestimonial.append('rating', rating);
            newTestimonial.append('comment', comment);
            newTestimonial.append('image', image); // Append the image file
            newTestimonial.append('video', video); // Append the image file

            const accessToken = localStorage.getItem('access_token');

            const response = await api.post(
                `reviews`,
                newTestimonial,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'multipart/form-data', // Set proper content type
                    },
                }
            );

            if (response.status === 201) {
                setIsLoading(false);
                onAddTestimonial(newTestimonial); // Add the new testimonial to the local state
                onClose(); // Close the dialog
            } else {
                setIsLoading(false);
                setError(response.data.message);
                // Handle error scenario here if needed
            }
        } catch (error) {
            setIsLoading(false);
            setError(error.response.data.message);
            // Handle error scenario here if needed
        }
    };




    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Create Review</DialogTitle>
            <DialogContent>
                <TextField
                    label="Name"
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value);
                        setNameError('');
                    }}
                    fullWidth
                    sx={{ mt: 2 }}
                    error={nameError !== ''}
                    helperText={nameError}
                />
                <TextField
                    label="Designation"
                    value={designation}
                    onChange={(e) => {
                        setDesignation(e.target.value);
                        setDesignationError('');
                    }}
                    fullWidth
                    sx={{ mt: 2 }}
                    error={designationError !== ''}
                    helperText={designationError}
                />
                <TextField
                    label="Rating"
                    value={rating}
                    onChange={(e) => {
                        setRating(e.target.value);
                        setRatingError('');
                    }}
                    fullWidth
                    sx={{ mt: 2 }}
                    error={ratingError !== ''}
                    helperText={ratingError}

                />
                <TextField
                    label="Comment"
                    value={comment}
                    onChange={(e) => {
                        setComment(e.target.value);
                        setCommentError('');
                    }}
                    fullWidth
                    sx={{ mt: 2 }}
                    error={commentError !== ''}
                    multiline
                    helperText={commentError}
                />
                <label htmlFor="image-input">
                    <Typography variant="subtitle1" gutterBottom>
                        Image
                    </Typography>
                    <input
                        id="image-input"
                        type="file"
                        accept="image/*"
                        onChange={(e) => setImage(e.target.files[0])}
                        style={{ display: 'none' }}
                    />
                    <Button
                        variant="outlined"
                        component="span"
                        color="primary"
                    >
                        Choose Image
                    </Button>
                </label>
                <label htmlFor="video-input">
                    <Typography variant="subtitle1" gutterBottom>
                        Video
                    </Typography>
                    <input
                        id="video-input"
                        type="file"
                        accept="video/*"
                        onChange={(e) => setVideo(e.target.files[0])}
                        style={{ display: 'none' }}
                    />
                    <Button
                        variant="outlined"
                        component="span"
                        color="primary"
                    >
                        Choose Video
                    </Button>
                </label>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="contained" color="error">
                    Cancel
                </Button>
                <Button
                    onClick={handleSave}
                    variant="contained"
                    color="success"
                    disabled={isLoading}
                >
                    {isLoading ? <CircularProgress size={20} /> : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddReviewVideo;