import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import axios from 'axios';

const LoginPage = ({ onLogin }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('');

    const handleLogin = async (e) => {
        e.preventDefault();

        // Validate email and password
        if (!email) {
            setEmailError(true);
            setEmailErrorMessage('Email is required');
            return;
        }

        if (!password) {
            setPasswordError(true);
            setPasswordErrorMessage('Password is required');
            return;
        }

        try {
            const response = await axios.post('http://api.2goforms.com/api/login', { email, password });
            if (response.data && response.data.access_token) {
                // Save the access token to localStorage
                localStorage.setItem('access_token', response.data.access_token);

                onLogin(response.data.access_token);
            }
        } catch (error) {
            console.log(error);
            setEmailError(true);
            setPasswordError(true);
            setPasswordErrorMessage('Invalid Credentials');
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5">
                    Login
                </Typography>
                <Box
                    component="form"
                    noValidate
                    onSubmit={handleLogin}
                    sx={{
                        mt: 1,
                        width: '100%', // Set the width to 100% for mobile responsiveness
                        '@media (min-width:600px)': {
                            width: '80%', // Set the width to 80% for screen sizes greater than 600px
                        },
                    }}
                >
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setEmailError(false); // Reset error state when user starts typing
                        }}
                        error={emailError}
                        helperText={emailError && emailErrorMessage}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value);
                            setPasswordError(false); // Reset error state when user starts typing
                        }}
                        error={passwordError}
                        helperText={passwordError && passwordErrorMessage}
                    />
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                        Login
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default LoginPage;