import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    CircularProgress,
    Typography,
    Card,
    CardContent,
    CardMedia,

} from '@mui/material';
import api from '../../Api/api'; // Import the custom api instance

const EditTestimonial = ({ testimonial, open, onClose, onSave }) => {
    const [name, setName] = useState(testimonial.name);
    const [designation, setDesignation] = useState(testimonial.designation);
    const [rating, setRating] = useState(testimonial.rating);
    const [comment, setComment] = useState(testimonial.comment);
    const [image, setImage] = useState('');
    const [imageUrl, setImageUrl] = useState(testimonial.image_url);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false); // Add a loading state

    const handleSave = async () => {
        setIsLoading(true);
        try {
            if (!name) {
                setError('Name is required');
                setIsLoading(false);
                return;
            }

            const updatedTestimonial = {
                ...testimonial,
                name,
                designation,
                rating,
                comment,
            };

            const editTestimonial = new FormData();
            editTestimonial.append('name', name);
            editTestimonial.append('designation', designation);
            editTestimonial.append('rating', rating);
            editTestimonial.append('comment', comment);
            editTestimonial.append('image', image); // Append the image file


            const accessToken = localStorage.getItem('access_token');

            const response = await api.post(
                `testimonials/${testimonial.id}`,
                editTestimonial,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'multipart/form-data', // Set proper content type
                    },
                }
            );

            if (response.status === 200) {
                setIsLoading(false);
                onSave(updatedTestimonial); // Update the testimonial in the local state
                onClose(); // Close the dialog
            } else {
                setIsLoading(false);
                setError(response.data.message);
                // Handle error scenario here if needed
            }
        } catch (error) {
            setIsLoading(false);
            setError(error.response.data.message);
            // Handle error scenario here if needed
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Edit Testimonial</DialogTitle>
            <DialogContent>
                <TextField
                    label="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    fullWidth
                    sx={{ mt: 2 }}
                    error={error !== ''}
                    helperText={error}
                />
                <TextField
                    label="Designation"
                    value={designation}
                    onChange={(e) => setDesignation(e.target.value)}
                    fullWidth
                    sx={{ mt: 2 }}
                />
                <TextField
                    label="Rating"
                    value={rating}
                    onChange={(e) => setRating(e.target.value)}
                    fullWidth
                    sx={{ mt: 2 }}
                />
                <TextField
                    label="Comment"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    fullWidth
                    sx={{ mt: 2 }}
                    multiline
                />
                <label htmlFor="image-input">
                    <Typography variant="subtitle1" gutterBottom>
                        Image
                    </Typography>
                    <input
                        id="image-input"
                        type="file"
                        accept="image/*"
                        onChange={(e) => setImage(e.target.files[0])} // Store the selected image as a File object
                        style={{ display: 'none' }}
                    />
                    <Button
                        variant="outlined"
                        component="span"
                        color="primary"
                    >
                        Choose Image
                    </Button>
                </label>
                {imageUrl && (
                    <Card sx={{ mt: 2 }}>
                        <CardMedia
                            component="img"
                            height="100%"
                            image={imageUrl}
                            alt="Testimonial Image"
                        />
                        <CardContent>
                            <Typography variant="body2" color="text.secondary">
                                Testimonial Image
                            </Typography>
                        </CardContent>
                    </Card>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="contained" color="error">
                    Cancel
                </Button>
                <Button
                    onClick={handleSave}
                    variant="contained"
                    color="success"
                    disabled={isLoading}
                >
                    {isLoading ? <CircularProgress size={20} /> : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditTestimonial;